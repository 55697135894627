<template>
  <CommonsModalsModalBody height="auto" :svg="svg">
    <template #title>Déclarer une note de frais</template>

    <template #help>
      <span
        >Une note de frais est un réglement effectué avec un moyen de paiement personnel pour le
        compte de la société. Comptabiliser les notes de frais permet de se faire rembourser par la
        société les sommes avancées.</span
      >
      <br ><br >
      <span>Importez vos notes de frais (maximum 10 documents de 2MB chacun).</span>
      <br ><br >
      <span>Formats autorisés : .png, .jpg, .jpeg, .pdf</span>
    </template>
    <template #content>
      <div class="uds-form-container">
        <div class="uds-form-row">
          <CommonsDropzoneContainer
            :extensions="basicImageAndPdfMimeType"
            :max-files="10"
            :max-size="1.9"
            :disabled="isLoading"
            :error="err.files"
            :show-drop-box-button="true"
            @updated="form.files = $event"
          />
        </div>

        <div v-if="shouldDisplayInputDocumentName" class="uds-form-row">
          <uds-input-string
            label="Nom du document"
            :value="form.nameFile"
            is-required
            @change="form.nameFile = $event || ''"
          />
        </div>
        <div v-if="shouldDisplaySelectDocumentType" class="uds-form-row">
          <uds-input-select-auto-popper
            label="Type de document"
            :value="form.documentType"
            :items="documentTypes"
            @select="form.documentType = $event || null"
          />
        </div>
        <div class="uds-form-row">
          <uds-input-checkbox
            v-if="addedFileIsPdfType"
            label="Séparer votre document en plusieurs pages"
            :is-checked="form.split"
            @input="form.split = !form.split"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <template v-if="isPourcentage()">
        <uds-main-button
          size="small"
          :disabled="!canEnableButton"
          :loading="isLoading"
          type="secondary"
          @click="isValid && !isLoading ? importExpenseNote(true) : null"
        >
          Annoter par moi-même
        </uds-main-button>
      </template>

      <uds-main-button
        size="small"
        :loading="isLoadingWithoutAnnotation"
        :disabled="!canEnableButton"
        :type="isPourcentage() ? 'primary' : 'secondary'"
        @click="isValid && !isLoading && importExpenseNote(false)"
      >
        {{ buttonAnnotateAfterText }}
      </uds-main-button>

      <template v-if="!isPourcentage()">
        <uds-main-button
          size="small"
          :disabled="!canEnableButton"
          :loading="isLoading"
          @click="isValid && !isLoading ? importExpenseNote(true) : null"
        >
          Annoter
        </uds-main-button>
      </template>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { AccountingFileType, Code } from "@silexpert/core";
import isEmpty from "lodash-es/isEmpty";
import { basicImageAndPdfMimeType } from "~/utils/file-type";

enum DOCUMENT_TYPE_ID {
  INVOICE = 1,
  TICKET = 2,
}

const { isPourcentage } = useBrandsComposable();
const thirdPartyStore = useThirdPartiesStore();
const annotateStore = useAnnotateStore();

defineProps<{
  svg?: string;
}>();

const emit = defineEmits(["cancel", "close"]);

const isLoading = ref(false);
const isLoadingWithoutAnnotation = ref(false);

const form = ref({
  nameFile: "",
  split: false,
  documentType: DOCUMENT_TYPE_ID.INVOICE,
  files: [] as File[],
});

const documentTypes = [
  { text: "Facture", value: DOCUMENT_TYPE_ID.INVOICE },
  { text: "Ticket de caisse", value: DOCUMENT_TYPE_ID.TICKET },
];

const buttonAnnotateAfterText = isPourcentage() ? "Annoter par notre équipe" : "Annoter plus tard";

const canEnableButton = computed(() => {
  return form.value.files.length > 0 && isValid.value;
});

const shouldDisplayInputDocumentName = computed(() => {
  return isArray(form.value.files) && form.value.files.length === 1;
});

const shouldDisplaySelectDocumentType = computed(() => {
  return isArray(form.value.files) && form.value.files.length < 2;
});

const addedFileIsPdfType = computed(() => {
  const mimeTypes: string[] = form.value.files.map((addedFile) => (addedFile as File).type);
  return mimeTypes.every((type) => type === "application/pdf");
});

watch(
  () => form.value.files,
  (files: File[]) => {
    if (shouldDisplayInputDocumentName.value) {
      form.value.nameFile = files[0].name;
    }
  },
  { deep: true, immediate: true },
);

const err = computed<{ [key: string]: string | null }>(() => {
  return {
    files: form.value.files.length === 0 ? "Un fichier est requis." : null,
    nameFile:
      isEmpty(form.value.nameFile) && shouldDisplayInputDocumentName.value
        ? "Le champ est requis."
        : null,
  };
});

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

async function importExpenseNote(shouldAnnotateImmediately: boolean) {
  if (shouldAnnotateImmediately) {
    isLoading.value = true;
  } else {
    isLoadingWithoutAnnotation.value = true;
  }
  await $silex()
    .accountingFiles.accountingFile({
      idAccountingType: AccountingFileType.PURCHASE_INVOICES,
      split: form.value.split,
      isPayPerso: true,
      bypassOcr: false,
      files: shouldDisplayInputDocumentName.value
        ? [renameFile(form.value.files[0])]
        : form.value.files,
    })
    .then(async (res) => {
      // refetch the list of tiers since the OCR (performed on the files) can create new ones
      thirdPartyStore.fetchThirdPartiesAndGenericThirdParties();
      if (shouldAnnotateImmediately) {
        annotateStore.importedTransactions = res ?? [];
        annotateStore.isExpenseNote = true;
        await navigateTo("/annotateAfterImport");
      }
      emit("cancel");
    })
    .catch(async (error: any) => {
      // @ts-expect-error
      if (error?.response?.data?.code === Code.tokenFailure) {
        $notifier().open({
          type: "error",
          content: "Votre session est expirée, merci de vous reconnecter.",
        });

        emit("close");
        await navigateTo("/login");
      } else {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    });
}

function renameFile(file: File): File {
  const fileName: string = form.value.nameFile ?? file.name;
  const newFile = new File([file], fileName, {
    type: file.type,
    lastModified: file.lastModified,
  });
  return newFile;
}
</script>

<style lang="scss" scoped>
.footer-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-info {
  margin-left: $uds-spacing-1;
  cursor: pointer;
}
</style>
