<template>
  <CommonsModalsModalBody height="auto" :svg="svg">
    <template #title>
      <span>Déclarer une note de frais</span>
    </template>

    <template #help>
      {{ help }}
      <span>Formats autorisés : .png, .jpg, .jpeg, .pdf</span>
    </template>
    <template #content>
      <CommonsDropzoneContainer
        :extensions="basicImageAndPdfMimeType"
        :max-files="10"
        :max-size="20"
        :disabled="isLoading"
        :error="err.files"
        :show-drop-box-button="true"
        @updated="expenseNote.files = $event"
      />

      <div class="modal-content uds-form-container">
        <div class="uds-form-row">
          <uds-input-calendar
            label="Date"
            is-required
            :error="err.dateValue"
            :date="expenseNote.dateValue"
            @change-date="expenseNote.dateValue = $event"
          />
          <uds-input-select-custom
            label="Personne morale ou physique"
            :value="partnerText"
            is-required
          >
            <template #inputContent>
              <div v-if="partnerText" class="no-overflow">{{ partnerText }}</div>
              <span v-else class="placeholder">Choisir le collaborateur</span>
            </template>
            <template #dropdownContent="{ hide }">
              <CommonsDropdownsPartnerDropdown
                :id-selected-society-partner="expenseNote.idSocietyPartner ?? undefined"
                @select="(expenseNote.idSocietyPartner = $event), hide()"
              >
                <template #creationmodal>
                  <div class="dropdown-bottom" @click="openNewPartnerModal(), hide()">
                    <div class="add-rounded">
                      <uds-icon icon-name="add" :color="udsColors.udsWhite" size="small" />
                    </div>
                    <div class="new-text">Créer une nouvelle personne</div>
                  </div>
                </template>
              </CommonsDropdownsPartnerDropdown>
            </template>
          </uds-input-select-custom>
        </div>

        <template v-if="!isSliced">
          <div class="uds-form-row">
            <uds-input-amount
              label="Montant TTC"
              :value="expenseNote.entries[0].amountAllTaxIncluded"
              is-required
              :error="err.amountAllTaxIncluded"
              @change="expenseNote.entries[0].amountAllTaxIncluded = $event || null"
            />

            <uds-input-select-popper
              v-if="!isNotSubjectToVat"
              label="TVA"
              placeholder="Choisir une TVA"
              :value="expenseNote.entries[0].vat"
              :items="vatItems"
              item-value="idTva"
              item-text="name"
              is-required
              :error="getSliceError(expenseNote.entries[0]).vat"
              @select="expenseNote.entries[0].vat = $event || null"
            />
          </div>
          <div class="uds-form-row">
            <uds-input-select-custom label="Catégorie" is-required>
              <template #inputContent>
                <span v-if="getCategoryText(expenseNote.entries[0].idAccount)">{{
                  getCategoryText(expenseNote.entries[0].idAccount)
                }}</span>
                <span v-else class="placeholder">Choisir la catégorie</span>
              </template>
              <template #dropdownContent="{ hide }">
                <CommonsDropdownsCategoryDropdown
                  :has-credit-categories="false"
                  :has-neutral-categories="true"
                  :has-debit-categories="true"
                  :is-asset="false"
                  :has-is-asset="false"
                  @select="typeof $event === 'number' ? updateCategory($event, 0) : null, hide()"
                  @close="hide()"
                />
              </template>
            </uds-input-select-custom>
          </div>
        </template>

        <template v-else>
          <div
            v-for="(slice, indexSlice) in expenseNote.entries"
            :key="`slice_${indexSlice}`"
            class="enm-block"
          >
            <div style="display: flex; align-items: center; justify-content: space-between">
              Division {{ indexSlice + 1 }}

              <uds-action-button
                class="icon-delete"
                icon-name="delete"
                size="small"
                :color="udsColors.udsNeutral800"
                @click="deleteSlice(indexSlice)"
              />
            </div>
            <div class="uds-form-row">
              <uds-input-string
                label="Libellé"
                :value="slice.designation"
                is-required
                :error="err.designation"
                @change="slice.designation = $event || null"
              />
              <uds-input-amount
                label="Montant TTC"
                :value="slice.amountAllTaxIncluded"
                is-required
                :error="err.amountAllTaxIncluded"
                @change="slice.amountAllTaxIncluded = $event || null"
              />
            </div>
            <div class="uds-form-row">
              <uds-input-select-popper
                v-if="!isNotSubjectToVat"
                label="TVA"
                placeholder="Choisir une TVA"
                :value="slice.vat"
                :items="vatItems"
                item-value="idTva"
                item-text="name"
                is-required
                :error="getSliceError(slice).vat"
                @select="slice.vat = $event || null"
              />
              <uds-input-select-custom label="Catégorie" is-required>
                <template #inputContent>
                  <div v-if="getCategoryText(slice.idAccount)" class="no-overflow">
                    {{ getCategoryText(slice.idAccount) }}
                  </div>
                  <span v-else class="placeholder">Choisir la catégorie</span>
                </template>
                <template #dropdownContent="{ hide }">
                  <CommonsDropdownsCategoryDropdown
                    :has-credit-categories="false"
                    :has-neutral-categories="true"
                    :has-debit-categories="true"
                    :is-asset="false"
                    :has-is-asset="false"
                    @select="
                      typeof $event === 'number' ? updateCategory($event, indexSlice) : null, hide()
                    "
                    @close="hide()"
                  />
                </template>
              </uds-input-select-custom>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button size="small" type="secondary" @click="addSlice()"> Diviser </uds-main-button>
      <uds-main-button
        size="small"
        :loading="isLoading"
        :disabled="!isValid"
        @click="isValid ? createExpenseNote() : null"
      >
        Enregistrer
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import type { CreateCashAndExpenseTransaction, FormEntry, TFile } from "@silexpert/core";
import { AccountingJournal, Vat } from "@silexpert/core";
import NewPartnerModal from "@/components/commons/modals/NewPartnerModal.vue";

const emit = defineEmits(["cancel", "close"]);

defineProps<{
  help: string;
  svg?: string;
}>();

const udsColors = getUdsColors();
const { isComptalib } = useBrandsComposable();
const societyStore = useSocietyStore();
const dayjs = useDayjs();

const isLoading = ref(false);

type vatType = number | { idVat: number; amount: number }[] | null;

const expenseNote = ref<Omit<CreateCashAndExpenseTransaction, "files"> & { files: File[] }>({
  idAccountingJournal: AccountingJournal.PER,
  files: [],
  dateValue: dayjs().format("YYYY-MM-DD"),
  idSocietyPartner: undefined as number | undefined,
  entries: [
    {
      designation: "Paiement personnel" as string,
      vat: null as vatType,
      amountAllTaxIncluded: undefined as number | undefined,
      idAccount: undefined as number | undefined,
    },
  ],
});

// Partner
const partnerStore = usePartnerStore();
const partners = computed(() => partnerStore.partners);

const partnerText = computed(() => {
  const matchingPartner = partners.value.find((p) => p.id === expenseNote.value.idSocietyPartner);
  if (isDefined(matchingPartner)) {
    if (isDefined(matchingPartner.company) && matchingPartner.company.length > 0) {
      return `${matchingPartner.company}`;
    }
    return `${matchingPartner.firstname} ${matchingPartner.lastname}`;
  }
  return "";
});

async function openNewPartnerModal() {
  const { open, close } = useModal({
    component: NewPartnerModal,
    attrs: {
      help: "",
      svg: isComptalib() ? "ComptalibPartnerSvg" : "PartnerSvg",
      onClose(idPartner: number | undefined): void {
        if (idPartner) {
          expenseNote.value.idSocietyPartner = idPartner;
        }
        close();
      },
    },
  });
  await open();
}

// Category
const categoryStore = useCategoryStore();
function getCategoryText(idAccount: number | undefined) {
  if (idAccount) {
    const categories = categoryStore.categories ?? [];

    return categories.filter((cat) => cat.id === idAccount)[0]?.name ?? null;
  }
  return null;
}

// Vat
const utils = useAnnotationUtilsComposable();
const filteredVats = utils.getVatsForAnnotating({
  intracom: false,
  margin: false,
  manual: true,
});

const isNotSubjectToVat = computed(() => {
  return societyStore.isNotSubjectToVat;
});

const vatItems = computed(() => {
  return filteredVats;
});

// Slices
const isSliced = computed(() => expenseNote.value.entries.length > 1);

function addSlice() {
  expenseNote.value.entries.push({
    designation: "Paiement personnel" as string,
    vat: null as vatType,
    amountAllTaxIncluded: undefined as number | undefined,
    idAccount: undefined as number | undefined,
  });
}

function deleteSlice(index: number) {
  if (expenseNote.value.entries.length > 1) {
    expenseNote.value.entries.splice(index, 1);
  }
}

// Validation
const err = computed<{ [key: string]: string | null }>(() => {
  const { dateValue, idSocietyPartner, files } = expenseNote.value;
  return {
    dateValue: !isDefined(dateValue) ? "Le champ est requis" : null,
    idSocietyPartner: !isDefined(idSocietyPartner) ? "Le champ est requis." : null,
    files: files.length === 0 ? "Veuillez importer un fichier" : null,
    entries: hasSlicesErr.value ? "Le champ est requis." : null,
  };
});

const hasSlicesErr = computed(() => {
  const slicesErrors =
    expenseNote.value.entries?.filter((p: FormEntry) => {
      return !isDefined(p.vat) || !isDefined(p.amountAllTaxIncluded) || !isDefined(p.idAccount);
    }) ?? [];
  return slicesErrors.length > 0;
});

function getSliceError(slice: FormEntry) {
  const { vat, amountAllTaxIncluded, idAccount } = slice;
  return {
    vat: !isDefined(vat) ? "Le champ est requis." : null,
    amountAllTaxIncluded: !isDefined(amountAllTaxIncluded) ? "Le champ est requis." : null,
    idAccount: !isDefined(idAccount) ? "Le champ est requis." : null,
  };
}

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return !hasSlicesErr.value;
});

function updateCategory(idAccount: number, sliceIndex: number) {
  expenseNote.value.entries[sliceIndex].idAccount = idAccount;
  const categories = categoryStore.categories ?? [];
  const category = categories.find((c) => c.id === idAccount);
  const vat = category?.idVat;
  expenseNote.value.entries[sliceIndex].vat = !isNotSubjectToVat.value ? vat : Vat.WITHOUT_TAXE.id;
}

async function createExpenseNote() {
  isLoading.value = true;

  await $silex()
    .cashAndExpense.createTransaction({
      ...expenseNote.value,
      // @ts-expect-error
      files: expenseNote.value.files as TFile[], // overrides file type for backend
    })
    .then(() => {
      emit("close");
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });

  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.modal-content {
  margin: $uds-spacing-1 0;
}

.enm-block {
  border: 1px solid $uds-neutral-400;
  background-color: $uds-neutral-50;
  border-radius: $uds-radius-2;
  padding: $uds-spacing-1 $uds-spacing-2 $uds-spacing-2 $uds-spacing-2;
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-1;

  :deep(.material-symbols-rounded:hover) {
    cursor: pointer !important;
  }
}

.no-overflow {
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 185px;
  text-overflow: ellipsis;
}
</style>
