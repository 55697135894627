<template>
  <CommonsModalsModalBody height="auto" :svg="svg">
    <template #title>
      <span>Déclarer une indemnité kilométrique</span>
    </template>

    <template #help>
      Une indemnité kilométrique est un remboursement par l'entreprise des frais liés à
      l'utilisation d'un véhicule personnel pour des déplacements professionnels. Ils sont
      comptabilisés comme des notes de frais.
      <br >
      <br >
      Le calcul est basé sur le barème de plus de 20 000 km (palier dont le montant remboursé est le
      plus faible), une régularisation aura lieu à la fin de l'année civile en fonction du nombre de
      kilomètres réels parcourus.
    </template>

    <template #content>
      <div class="kam-content">
        <uds-input-select-custom label="Véhicule" is-required>
          <template #inputContent>
            <span v-if="vehicleText" class="truncate-text">{{ vehicleText }}</span>
            <span v-else class="placeholder">Choisir le véhicule</span>
          </template>
          <template #dropdownContent="{ hide }">
            <div class="uds-dropdown-list">
              <div
                v-for="(item, index) in vehicles"
                :key="index"
                class="uds-dropdown-line"
                @click="(form.idSocietyVehicle = item.id), hide()"
              >
                <span class="uds-dropdown-item">
                  {{ item.name }}
                </span>
              </div>
            </div>
            <CommonsAddItem
              label="Nouveau véhicule"
              style="margin: unset; padding-bottom: 8px"
              @click="
                openNewVehicleModal();
                hide();
              "
            />
          </template>
        </uds-input-select-custom>

        <div class="col-2">
          <uds-input-calendar
            label="Date"
            :error="err.dateValue"
            is-required
            :date="form.dateValue"
            @change-date="form.dateValue = $event"
          />

          <uds-input-amount
            label="Distance en km (Arrondi au km)"
            :value="form.distance"
            is-required
            hide-icon
            :error="err.distance"
            @change="form.distance = $event || null"
          />
        </div>

        <uds-input-string
          label="Motif"
          :value="form.reason"
          is-required
          :error="err.reason"
          @change="form.reason = $event || ''"
        />

        <div class="col-2">
          <uds-input-string
            label="Ville de départ"
            :value="form.departureCity"
            is-required
            :error="err.departureCity"
            @change="form.departureCity = $event || ''"
          />
          <uds-input-string
            label="Ville d'arrivée"
            :value="form.arrivalCity"
            is-required
            :error="err.arrivalCity"
            @change="form.arrivalCity = $event || ''"
          />
        </div>

        <uds-input-select-auto-popper
          label="Collaborateur"
          is-required
          :value="form.idSocietyPartner"
          :items="formattedPartners"
          item-value="idSocietyPartner"
          item-text="formattedName"
          :error="err.idSocietyPartner"
          @select="form.idSocietyPartner = $event || null"
        />
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button
        size="small"
        :loading="isLoading"
        :disabled="!isValid"
        @click="isValid ? createKilometricAllowance() : null"
      >
        Enregistrer
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { CreateKilometricAllowance, ISocietyPartner, ISocietyVehicle } from "@silexpert/core";
import { PartnerType } from "@silexpert/core";
import { useModal } from "vue-final-modal";
import NewVehicleModal from "@/components/commons/modals/NewVehicleModal.vue";

const emit = defineEmits(["cancel", "close"]);
const dayjs = useDayjs();

const { isComptalib } = useBrandsComposable();
const vehiclesStore = useVehicleStore();
const societyStore = useSocietyStore();
const exerciceStore = useExerciceStore();
const partnerStore = usePartnerStore();

const societyExercices = computed(() => exerciceStore.exercices);

const svg = isComptalib() ? "ComptalibKilometricAllowanceSvg" : "KilometricAllowanceSvg";

const isLoading = ref(false);

const form = ref({
  idSocietyVehicle: undefined as number | undefined,
  dateValue: dayjs().format("YYYY-MM-DD"),
  distance: undefined,
  reason: undefined,
  departureCity: undefined,
  arrivalCity: undefined,
  idSocietyPartner: undefined,
});

const idSociety = computed(() => societyStore?.society?.id ?? 0);

const exerciceValidationCheckCache: { id: number; validated: boolean }[] = [];

onMounted(async () => {
  if (vehiclesStore.vehicles.length === 0) {
    vehiclesStore.fetchSocietyVehicles();
  }
  try {
    const bsms = await $silex().balanceSheetModule.getBalanceSheetModules(idSociety.value);
    bsms.forEach((bsm) => {
      const exerciceBsm = societyExercices.value.find((ex) => ex.id === bsm.idExercice);
      if (exerciceBsm) {
        exerciceValidationCheckCache.push({
          id: exerciceBsm.id ?? 0,
          validated: bsm.hasValidateKilometricAllowance ?? false,
        });
      }
    });
  } catch {
    $notifier().open({
      type: "error",
      content: "Une erreur est survenue lors de l'import",
    });
  }
});

const vehicles = computed<ISocietyVehicle[]>(() => {
  return vehiclesStore.vehicles;
});

const vehicleText = computed<string | null>(() => {
  return vehicles.value.find((v) => v.id === form.value.idSocietyVehicle)?.name ?? null;
});

const partners = computed<ISocietyPartner[]>(() => {
  return partnerStore.getPartnersForAnnotating;
});

const formattedPartners = computed<{ idSocietyPartner: number; formattedName: string }[]>(() => {
  return partners.value.map((p) => {
    return {
      idSocietyPartner: p.id!,
      formattedName:
        p.idPartnerType === PartnerType.MORAL_PARTNER
          ? `${p.company}`
          : `${p.firstname} ${p.lastname}`,
    };
  });
});

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

const err = computed<{ [key: string]: string | null }>(() => {
  const {
    idSocietyVehicle,
    dateValue,
    reason,
    distance,
    departureCity,
    arrivalCity,
    idSocietyPartner,
  } = form.value;
  return {
    idSocietyVehicle: !isDefined(idSocietyVehicle) ? "Le champ est requis." : null,
    dateValue: isAllowedToCreateKilometricAllowance(dateValue)
      ? !isDefined(dateValue)
        ? "Le champ est requis."
        : null
      : "vous avez déjà validé vos indemnités kilométriques pour cette période.",
    distance: !isDefined(distance) ? "Le champ est requis." : null,
    reason: !isDefined(reason) ? "Le champ est requis." : null,
    departureCity: !isDefined(departureCity) ? "Le champ est requis." : null,
    arrivalCity: !isDefined(arrivalCity) ? "Le champ est requis." : null,
    idSocietyPartner: !isDefined(idSocietyPartner) ? "Le champ est requis." : null,
  };
});

function getExerciceIdFromDate(date: string | null): number | null {
  const exericeWithinDate = societyExercices.value.find(
    (ex) => dayjs(date).isAfter(dayjs(ex.startDate)) && dayjs(ex.endDate).isAfter(dayjs(date)),
  );
  return exericeWithinDate?.id ?? null;
}

function isAllowedToCreateKilometricAllowance(date: string | null): boolean {
  const idExercice = getExerciceIdFromDate(date) ?? 0;
  const check = exerciceValidationCheckCache.find((ev) => ev.id === idExercice) ?? null;
  if (!check) {
    return true;
  }
  return !check.validated;
}

async function openNewVehicleModal() {
  const { open, close } = useModal({
    component: NewVehicleModal,
    attrs: {
      help: `Ajouter un véhicule
      Ajoutez ici les véhicules que vous utilisez dans le cadre de votre activité. Le véhicule peut être détenu par vous même ou par la société.
      Cela vous permettra de déclarer des indemnités kilométriques afin de rembourser vos frais d'essence avancés avec votre véhicule personnel.
      Dans tous les cas, n'oubliez pas de joindre la carte grise.
      `,
      idVehicle: null,
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

async function createKilometricAllowance() {
  isLoading.value = true;

  const payload: CreateKilometricAllowance = {
    idSocietyVehicle: form.value.idSocietyVehicle!,
    reason: form.value.reason!,
    distance: form.value.distance!,
    departureCity: form.value.departureCity!,
    arrivalCity: form.value.arrivalCity!,
    idSocietyPartner: form.value.idSocietyPartner!,
    dateValue: new Date(form.value.dateValue),
  };

  try {
    await $silex().cashAndExpense.createKilometricAllowance(idSociety.value, payload);
    emit("close");
    $notifier().open({ type: "success", content: "Indemnité kilométrique enregistrée" });
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  }
}
</script>

<style lang="scss" scoped>
.kam-content {
  max-width: 590px;
}
.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $uds-spacing-2;
  margin-bottom: $uds-spacing-2;
  margin-top: $uds-spacing-2;
}
</style>
